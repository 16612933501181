<template>
  <div class="login">
    <img src="../assets/cxLogo.png" alt="" class="cxLogo" />
    <div class="loginForm">
      <div class="phone">
        <img src="../assets/phone.png" alt="" />
        <input type="text" v-model="phoneValue" placeholder="请输入手机号码" />
      </div>
      <div class="phone yzm">
        <img src="../assets/yzm.png" alt="" />
        <input type="text" v-model="yzmValue" placeholder="请输入验证码" />
        <div class="yzmDjs" v-if="!djsTF" @click="getYzmData()">获取验证码</div>
        <div class="yzmDjs" v-if="djsTF">{{ djsValue }}s</div>
      </div>
      <div class="phone yqm" v-if="uuid != ''">
        <div>邀请码</div>
        <input type="text" v-model="uuid" readonly placeholder="请输入邀请码" />
      </div>
      <div class="loginButton" @click="goBack()">登录</div>
      <div class="yhxy">
        <img
          v-if="!yhTF"
          @click="changeYhTF()"
          src="../assets/unselect.png"
          alt=""
        />
        <img
          v-if="yhTF"
          @click="changeYhTF()"
          src="../assets/select.png"
          alt=""
        />
        <p>我已阅读并同意<span>《用户协议和隐私政策》</span></p>
      </div>
    </div>
  </div>
</template>
<script>
import { resetRouter } from "../router/index.js";

import { getYzm, postLogin } from "../api/login.js";
import { Toast } from "@nutui/nutui";
export default {
  data() {
    return {
      phoneValue: "",
      yzmValue: "",
      timer: "",
      djsValue: "",
      djsTF: false,
      yhTF: false,
      uuid: "",
    };
  },
  setup() {},
  beforeRouteLeave(to, from, next) {
    if (to.path == "/person") {
      next("/index");
    } else {
      next();
    }
  },
  mounted() {
    if (this.$route.query.uuid) {
      this.uuid = this.$route.query.uuid;
      console.log(this.uuid);
    }
  },
  methods: {
    goBack() {
      if (this.yanzheng(this.phoneValue)) {
        if (this.yhTF) {
          let params = {
            phone: this.phoneValue,
            code: this.yzmValue,
            uuid: this.uuid,
          };
          postLogin(params).then((res) => {
            if (res.code == 1) {
              Toast.text(res.msg);
              localStorage.setItem("app_token", res.data.app_token);
              if (this.uuid != "") {
                this.$router.push("/index");
              } else {
                this.$router.go(-1);
              }
            } else {
              Toast.text(res.msg);
            }
          });
        } else {
          Toast.text("请先阅读并同意用户隐私协议");
        }
      } else {
        Toast.text("请输入正确手机号");
      }
    },
    changeYhTF() {
      this.yhTF = !this.yhTF;
    },
    yanzheng(data) {
      var regExp = new RegExp("^1[3578]\\d{9}$");
      return regExp.test(data);
    },
    getYzmData() {
      if (this.yanzheng(this.phoneValue)) {
        this.djsTF = true;
        this.djsValue = 60;
        let params = {
          phone: this.phoneValue,
          type: 3,
        };
        getYzm(params).then((res) => {
          Toast.text(res.msg);
          this.timer = setInterval(() => {
            if (this.djsValue == 0) {
              clearInterval(this.timer);
              this.djsTF = false;
            } else {
              this.djsValue -= 1;
            }
          }, 1000);
        });
      } else {
        Toast.text("请输入正确手机号");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.login {
  .cxLogo {
    width: 142px;
    margin: 0 auto;
    margin-top: 65px;
    margin-bottom: 42px;
  }
  .loginForm {
    padding: 0 25px;
    .phone {
      width: 100%;
      position: relative;
      height: 47px;
      img {
        width: 15px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 24px;
        margin: auto;
      }
      input {
        width: 100%;
        height: 100%;
        background: #ffffff;
        border-radius: 24px;
        font-size: 14px;
        display: block;
        text-indent: 53px;
      }
    }
    .yzm {
      margin-top: 20px;
      img {
        width: 18px;
      }
      .yzmDjs {
        position: absolute;
        right: 15px;
        top: 0;
        font-size: 14px;
        height: 100%;
        display: flex;
        align-items: center;
      }
    }
    .yqm {
      margin-top: 20px;
      div {
        position: absolute;
        left: 0;
        top: 0;
        font-size: 14px;
        height: 100%;
        display: flex;
        align-items: center;
        text-indent: 20px;
      }
      input {
        text-indent: 70px;
      }
    }
    .loginButton {
      height: 47px;
      background: linear-gradient(30deg, #8ddaf3 0%, #c1d2ff 100%);
      border-radius: 24px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 47px;
      text-align: center;
      margin-top: 40px;
    }
    .yhxy {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 18px;
      img {
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }
      p {
        font-size: 13px;
        color: #333333;
        span {
          color: #0077cd;
        }
      }
    }
  }
}
</style>